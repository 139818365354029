import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Brands from './brands/Brands';
import Categories from './categories/Categories';
import Complain from './complains/Complains';
import Users from './users/Users';
import Faqs from './faqs/Faqs';
import Roles from './roles/Roles';
import Posts from './posts/Posts';
import Orders from './orders/Orders';
import Returns from './returns/Returns';
import Dashboard from './dashboard/Dashboard';
import Inputs from './Inputs';
import withLayout from '../components/ui/Layout';
import {useIntl} from 'react-intl';
import OrdersReports from './orders_reports/OrdersReports';
import SupportCases from "./support_cases/SupportCases";

function MainContainer(props) {
    const intl = useIntl();

    return (
        <Switch>
            <Route
                exact
                path='/'
                component={withLayout(
                    Dashboard,
                    intl.formatMessage({id: 'MENU.DASHBOARD'}),
                )}
            />
            <Route path='/brands' component={Brands} />
            <Route path='/categories' component={Categories} />
            <Route path='/users' component={Users} />
            <Route path='/roles' component={Roles} />
            <Route path='/posts' component={Posts} />
            <Route path='/complains' component={Complain} />
            <Route path='/orders' component={Orders} />
            <Route path='/orders-reports' component={OrdersReports} />
            <Route path='/returns' component={Returns} />
            <Route path='/inputs' component={Inputs} />
            <Route path='/faqs' component={Faqs} />
            <Route path='/support-cases' component={SupportCases} />
        </Switch>
    );
}

const logout = function () {
    return {
        type: 'LOGOUT',
    };
};

const mapStateToProps = function (state) {
    return {
        user: state.sessionReducer.user,
        title: state.testReducer.title,
        content: state.testReducer.content,
    };
};

const mapDispatchToProps = {
    logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
