import React from 'react';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import SupportCase from '../../common/models/SupportCase';
import Alert from 'react-bootstrap/Alert';
import Can from '../../common/security/Can';
import Button from 'react-bootstrap/Button';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import LoadingButton from '../../components/form/LoadingButton';
import SendReviewForm from '../../components/form/support_case/SendReviewForm';

export class SupportCaseDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supportCase: new SupportCase(),
            error: '',
            isLoadErrorModalOpen: false,
            isReviewModalOpen: false,
            doneMessage: '',
            loading: false,
        };
    }

    componentDidMount() {
        return this.fetchSupportCase();
    }

    fetchSupportCase = async () => {
        this.setState({
            loading: true,
        });

        try {
            const {supportCase} = await API.supportCases.detail(
                this.props.match.params.id,
            );

            this.setState({
                supportCase,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
                isLoadErrorModalOpen: true,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        try {
            const {supportCase} = await API.supportCases.review(
                input,
                this.props.match.params.id,
            );

            this.setState(
                {
                    loading: false,
                    supportCase,
                    doneMessage: this.props.intl.formatMessage({
                        id: 'SUPPORT_CASES.REVIEW_DONE',
                    }),
                    isReviewModalOpen: false,
                },
                () => this.cleanAlertsMessages('doneMessage'),
            );
        } catch (e) {
            this.setState(
                {
                    loading: false,
                    error: this.props.intl.formatMessage({
                        id: 'SUPPORT_CASES.SUBMIT_ERROR',
                    }),
                    isReviewModalOpen: false,
                },
                () => this.cleanAlertsMessages('error'),
            );
        }
    };

    toggleReviewModal = () => {
        this.setState({
            isReviewModalOpen: !this.state.isReviewModalOpen,
        });
    };

    cleanAlertsMessages = (field) => {
        setTimeout(() => {
            this.setState({
                [field]: '',
            });
        }, 5000);
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <Alert
                    variant='success'
                    className='mb-4'
                    show={!!this.state.doneMessage}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.doneMessage}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Can run='SUPPORTCASES_REVIEW'>
                        <Button
                            className='btn-primary btn-bold mx-2 btn'
                            disabled={
                                this.state.supportCase.status === 'closed'
                            }
                            onClick={this.toggleReviewModal}
                        >
                            {this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.REVIEW_BUTTON',
                            })}
                        </Button>
                    </Can>
                    <Button
                        as={NavLink}
                        to='/support-cases'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card data-test='detailSupportComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'SUPPORT_CASES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'SUPPORT_CASES.ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.supportCase.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'SUPPORT_CASES.USER_LABEL',
                                    })}
                                </label>
                                <p>{this.state.supportCase.userFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'SUPPORT_CASES.COMMENTS_LABEL',
                                    })}
                                </label>
                                <p>{this.state.supportCase.comments}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'SUPPORT_CASES.STATUS_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.supportCase.status &&
                                        this.props.intl.formatMessage({
                                            id: `SUPPORT_CASES.STATUS_${this.state.supportCase.status.toUpperCase()}`,
                                        })}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'SUPPORT_CASES.CREATED_AT_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.supportCase.createdAt &&
                                        new Date(
                                            this.state.supportCase.createdAt,
                                        ).toLocaleDateString()}
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {this.state.supportCase.reviews.length > 0 && (
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'SUPPORT_CASES.REVIEWS_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            {this.state.supportCase.reviews.map((review, i) => (
                                <React.Fragment key={`review-${i}`}>
                                    <Row>
                                        <Col sm={6}>
                                            <label className='field-title'>
                                                {this.props.intl.formatMessage({
                                                    id:
                                                        'SUPPORT_CASES.COMMENTS_LABEL',
                                                })}
                                            </label>
                                            <p>{review.comments}</p>
                                        </Col>
                                        <Col sm={6}>
                                            <label className='field-title'>
                                                {this.props.intl.formatMessage({
                                                    id:
                                                        'SUPPORT_CASES.CREATED_AT_LABEL',
                                                })}
                                            </label>
                                            <p>
                                                {new Date(
                                                    review.createdAt,
                                                ).toLocaleDateString()}
                                            </p>
                                        </Col>
                                        <Col
                                            sm={6}
                                            className={
                                                i + 1 !==
                                                this.state.supportCase.reviews
                                                    .length
                                                    ? 'mb-4'
                                                    : ''
                                            }
                                        >
                                            <label className='field-title'>
                                                {this.props.intl.formatMessage({
                                                    id:
                                                        'SUPPORT_CASES.STATUS_LABEL',
                                                })}
                                            </label>
                                            <p>
                                                {this.props.intl.formatMessage({
                                                    id: `SUPPORT_CASES.STATUS_${review.status.toUpperCase()}`,
                                                })}
                                            </p>
                                        </Col>
                                    </Row>
                                    {i + 1 !==
                                        this.state.supportCase.reviews
                                            .length && <hr />}
                                </React.Fragment>
                            ))}
                        </Card.Body>
                    </Card>
                )}
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'SUPPORT_CASES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchSupportCase}
                        >
                            {this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.isReviewModalOpen}
                    onHide={this.toggleReviewModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.SEND_REVIEW',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendReviewForm
                            toggleReviewModal={this.toggleReviewModal}
                            loading={this.state.loading}
                            handleSubmit={this.onSubmit}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default injectIntl(SupportCaseDetail);
