import React from 'react';

import API from '../../common/utils/API';
import StarterList from '../../components/starterList/StarterList';
import {OrderStatus, ShipmentStatus} from '../../common/config/constants';
import {injectIntl} from 'react-intl';

export class OrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            search: '',
            status: '',
            userId: '',
            sellerId: '',
            startsAt: '',
            endsAt: '',
            shipmentStatus: '',
        };
    }

    componentDidMount() {
        return this.fetchOrders();
    }

    fetchOrders = async (page = 1) => {
        const result = await API.orders.get(
            page,
            this.state.search,
            this.state.status,
            this.state.shipmentStatus,
            this.state.userId,
            this.state.sellerId,
            this.state.startsAt,
            this.state.endsAt,
        );

        this.setState({
            orders: result.items,
            page: result.page,
            pageSize: result.pageSize,
            pageCount: result.pageCount,
            total: result.total,
        });
    };

    onPageChange = (page) => {
        return this.fetchOrders(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchOrders(1);
            }, 300),
        });
    };

    onAutocompleteOrDateSearchChange = (value, name) => {
        this.setState({
            [name]: value?.id || value || '',
            searchTimeout: setTimeout(() => {
                return this.fetchOrders(1);
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                return this.fetchOrders(1);
            },
        );
    };

    handleAutocompleteSearch = async (query) => {
        const {items: users} = await API.users.get(1, query);
        const options = users.map((user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
        }));

        return options;
    };

    render() {
        return (
            <>
                <StarterList
                    data-test='orderListComponent'
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    onAutocompleteOrDateSearchChange={
                        this.onAutocompleteOrDateSearchChange
                    }
                    handleAutocompleteSearch={this.handleAutocompleteSearch}
                    searchKeyword={this.state.search}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'ORDERS.ORDER_BUYER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS.ORDER_SALESMAN_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS.ORDER_TOTAL_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'ORDERS.ORDER_TOTAL_FOR_SALESMAN_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS.ORDER_PAYED_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS.ORDER_DATE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS.ORDER_STATUS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS.ORDER_SHIPPING_STATUS_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.orders.map((order) => ({
                            content: [
                                order.id,
                                order.buyerFullName,
                                order.sellerFullName,
                                `$ ${order.total.toFixed(2)}`,
                                `$ ${order.payable.toFixed(2)}`,
                                order.payedAt || order.payable === 0
                                    ? this.props.intl.formatMessage({
                                          id: 'GENERAL.YES',
                                      })
                                    : this.props.intl.formatMessage({
                                          id: 'GENERAL.NO',
                                      }),
                                new Date(order.createdAt).toLocaleDateString(),
                                this.props.intl.formatMessage({
                                    id: `ORDERS.STATUS_${order.status.toUpperCase()}`,
                                }),
                                this.props.intl.formatMessage({
                                    id: `ORDERS.SHIPMENT_STATUS_${order.shipment.status?.toUpperCase()}`,
                                }),
                            ],
                            link: `/orders/${order.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            name: 'userId',
                            value: this.state.userId,
                            autocomplete: true,
                            labelKey: 'name',
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.TYPE_HERE_TO_SEARCH_BY_USER',
                            }),
                        },
                        {
                            name: 'sellerId',
                            value: this.state.sellerId,
                            autocomplete: true,
                            labelKey: 'name',
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.TYPE_HERE_TO_SEARCH_BY_SELLER',
                            }),
                        },
                        {
                            name: 'startsAt',
                            value: this.state.startsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_FROM',
                            }),
                        },
                        {
                            name: 'endsAt',
                            value: this.state.endsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_TO',
                            }),
                        },
                        {
                            name: 'status',
                            value: this.state.status,
                            placeholder: this.props.intl.formatMessage({
                                id: 'ORDERS.STATUS_PLACEHOLDER',
                            }),
                            options: Object.values(OrderStatus).map(
                                (value) => ({
                                    value,
                                    label: this.props.intl.formatMessage({
                                        id: `ORDERS.STATUS_${value.toUpperCase()}`,
                                    }),
                                }),
                            ),
                        },
                        {
                            name: 'shipmentStatus',
                            value: this.state.shipmentStatus,
                            placeholder: this.props.intl.formatMessage({
                                id: 'ORDERS.SHIPMENT_STATUS_PLACEHOLDER',
                            }),
                            options: Object.values(ShipmentStatus).map(
                                (value) => ({
                                    value,
                                    label: this.props.intl.formatMessage({
                                        id: `ORDERS.SHIPMENT_STATUS_${value.toUpperCase()}`,
                                    }),
                                }),
                            ),
                        },
                    ]}
                />
            </>
        );
    }
}

export default injectIntl(OrderList);
