import React from 'react';
import BaseForm from 'react-bootstrap/Form';
import {Formik} from 'formik';
import * as Yup from 'yup';

import Input from '../Input';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/Button';
import LoadingButton from '../LoadingButton';
import {SupportStatus} from '../../../common/config/constants';

export default function SendReviewForm(props) {
    const intl = useIntl();
    const validationSchema = Yup.object({
        comments: Yup.string(),
        status: Yup.string().required(
            intl.formatMessage({
                id: 'SUPPORT_CASES.VALIDATION.STATUS_MISSING',
            }),
        ),
    });

    const status = Object.values(SupportStatus).filter(
        (status) => status !== SupportStatus.PENDING,
    );

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const getInitialValues = () => {
        return {
            comments: '',
            status: '',
        };
    };

    return (
        <Formik
            data-test='supportCaseSendReviewForm'
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <BaseForm.Row>
                            <Input
                                sm='12'
                                type='select'
                                label={intl.formatMessage({
                                    id: 'SUPPORT_CASES.STATUS_LABEL',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'SUPPORT_CASES.STATUS_PLACEHOLDER',
                                })}
                                name={'status'}
                                value={values.status}
                                onChange={handleChange}
                                error={errors.status}
                                isInvalid={errors.status}
                                children={status.map((supportCaseStatus, i) => (
                                    <option
                                        key={`status-${i}`}
                                        value={supportCaseStatus}
                                    >
                                        {intl.formatMessage({
                                            id: `SUPPORT_CASES.STATUS_${supportCaseStatus.toUpperCase()}`,
                                        })}
                                    </option>
                                ))}
                            />
                            <Input
                                sm='12'
                                type='textarea'
                                label={intl.formatMessage({
                                    id: 'SUPPORT_CASES.EMAIL_TEXT_LABEL',
                                })}
                                placeholder={intl.formatMessage({
                                    id: 'SUPPORT_CASES.EMAIL_TEXT_PLACEHOLDER',
                                })}
                                name='comments'
                                value={values.comments}
                                onChange={handleChange}
                                error={errors.comments}
                                isInvalid={errors.comments}
                            />
                        </BaseForm.Row>
                        <BaseForm.Row className='justify-content-sm-end'>
                            <Button
                                type='button'
                                className='btn-bold mx-2 btn btn-dark btn-light-dark'
                                onClick={props.toggleReviewModal}
                            >
                                {intl.formatMessage({
                                    id: 'SUPPORT_CASES.RETURN_CANCEL',
                                })}
                            </Button>
                            <LoadingButton
                                type='submit'
                                variant='success'
                                className='btn-bold'
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'SUPPORT_CASES.ACCEPT_BUTTON',
                                })}
                            </LoadingButton>
                        </BaseForm.Row>
                    </BaseForm>
                </>
            )}
        </Formik>
    );
}
