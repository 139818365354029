import React from 'react';
import StarterList from '../../components/starterList/StarterList';
import API from '../../common/utils/API';
import {injectIntl} from 'react-intl';

class CategoryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
        };
    }

    componentDidMount() {
        this.fetchPage();
    }

    fetchPage = () => {
        API.categories.get().then((result) => {
            this.setState({
                categories: result.items,
            });
        });
    };

    render() {
        return (
            <>
                <StarterList
                    total={this.state.categories.length}
                    hideSearch={true}
                    hidePagination={true}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'CATEGORIES.CATEGORY_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'CATEGORIES.CATEGORY_ICON_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.categories.map((category) => ({
                            content: [
                                category.id,
                                category.name,
                                category.iconUrl,
                            ],
                            link: `/categories/${category.id}`,
                            color: category.bgColor,
                        })),
                    }}
                    addNewPermission={'CATEGORIES_CREATE'}
                    addNewLink={`categories/create`}
                />
            </>
        );
    }
}

export default injectIntl(CategoryList);
