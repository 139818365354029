import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect, NavLink} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

import API from '../../common/utils/API';
import Post from '../../common/models/Post';
import Input from '../../components/form/Input';
import LoadingButton from '../../components/form/LoadingButton';

class PostEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: '',
            post: new Post(),
            categories: [],
            brands: [],
            attributes: [],
            attributesMap: {},
            error: '',
            errors: {},
            redirect: false,
        };
    }

    componentDidMount = async () => {
        await this.fetchItem();
    };

    fetchItem = async () => {
        try {
            const [
                {post},
                {categories},
                {brands},
                {attributes},
            ] = await Promise.all([
                API.posts.detail(this.props.match.params.id),
                API.posts.categories(),
                API.posts.brands(),
                API.categories.attributes(),
            ]);
            const attributesMap = attributes.reduce(
                (prev, a) => Object.assign(prev, {[a.id]: a}),
                {},
            );
            this.setState(
                {
                    post,
                    categories,
                    brands,
                    attributesMap,
                },
                () => this.setCategory(),
            );
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
            });
        }
    };

    onFieldChange = (event) => {
        const {name, value} = event.target;
        const post = this.state.post;
        post[name] = value;
        this.setState({post}, () => {
            name === 'categoryId' && this.setCategory();
            this.validate(name);
        });
    };

    validateRequired(field, value) {
        if (value != null && `${value}`.trim()) {
            return;
        }
        return this.props.intl.formatMessage(
            {
                id: 'VALIDATION.REQUIRED',
            },
            {field},
        );
    }

    validateNumeric(field, value) {
        if (/^(\d*\.)?\d+$/g.test(value)) {
            return;
        }
        return this.props.intl.formatMessage(
            {
                id: 'VALIDATION.NUMERIC',
            },
            {field},
        );
    }

    validate = (field) => {
        const {post, errors, attributes} = this.state;
        delete errors[field];

        const required = [
            'name',
            'description',
            'categoryId',
            'brandId',
            'originalPrice',
            'price',
            ...attributes.map((attr) => `attribute${attr.id}`),
        ];
        if (
            required.includes(field) ||
            (field === 'brandName' && +post.brandId === -1)
        ) {
            const error = this.validateRequired(field, post[field]);
            if (error) {
                errors[field] = error;
                this.setState({errors});
                return false;
            }
        }

        const numeric = ['originalPrice', 'price'];
        if (numeric.includes(field)) {
            const error = this.validateNumeric(field, post[field]);
            if (error) {
                errors[field] = error;
                this.setState({errors});
                return false;
            }
        }

        this.setState({errors});
        return true;
    };

    setCategory() {
        const {post, attributesMap, categories} = this.state;
        const category = categories.find((c) => c.id === +post.categoryId);
        const attributes =
            category?.attributes
                ?.map((a) => attributesMap[a.id])
                .filter((a) => a) || [];
        this.setState({attributes});
    }

    onSubmit = async () => {
        const {attributes} = this.state;
        const fields = [
            'name',
            'description',
            'categoryId',
            'brandId',
            'brandName',
            'childGenderCode',
            'originalPrice',
            'price',
            ...attributes.map((attr) => `attribute${attr.id}`),
        ];
        const valid = fields.reduce(
            (prev, field) => prev && this.validate(field),
            true,
        );
        if (!valid) {
            return;
        }
        const {post} = this.state;
        const data = {
            name: post.name,
            description: post.description,
            addressId: post.addressId,
            userId: post.userId,
            categoryId: post.categoryId,
            brandId: post.brandId > 0 ? post.brandId : undefined,
            brandName: post.brandName,
            originalPrice: post.originalPrice,
            price: post.price,
            pictures: post.pictures,
            isBargainable: post.isBargainable,
            attributes: attributes.map((attr) => ({
                id: attr.id,
                optionId: post[`attribute${attr.id}`],
            })),
        };
        this.setState({
            loading: 'loading',
            error: '',
        });
        try {
            await API.posts.update(this.props.match.params.id, data);
            this.setState({
                redirect: true,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
            });
        }
        this.setState({
            loading: '',
        });
    };

    renderRedirect = () => {
        if (!this.state.redirect) {
            return;
        }
        return (
            <Redirect
                to={{
                    pathname: `/posts/${this.props.match.params.id}`,
                    state: {
                        successMessage: 'Post updated!',
                    },
                }}
            />
        );
    };

    render() {
        return (
            <>
                {this.renderRedirect()}
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to={`/posts/${this.props.match.params.id}`}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'GENERAL.CANCEL',
                        })}
                    </Button>
                </div>
                <Form onSubmit={this.onSubmit}>
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'POSTS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Input
                                    md='6'
                                    type='textarea'
                                    label={this.props.intl.formatMessage({
                                        id: 'POSTS.NAME_LABEL',
                                    })}
                                    name='name'
                                    value={this.state.post.name}
                                    onChange={this.onFieldChange}
                                    error={this.state.errors.name}
                                    isInvalid={this.state.errors.name}
                                />
                                <Input
                                    md='6'
                                    type='textarea'
                                    label={this.props.intl.formatMessage({
                                        id: 'POSTS.DESCRIPTION_LABEL',
                                    })}
                                    name='description'
                                    value={this.state.post.description}
                                    onChange={this.onFieldChange}
                                    error={this.state.errors.description}
                                    isInvalid={this.state.errors.description}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Input
                                    md='6'
                                    type='select'
                                    label={this.props.intl.formatMessage({
                                        id: 'POSTS.CATEGORY_LABEL',
                                    })}
                                    name='categoryId'
                                    value={this.state.post.categoryId}
                                    onChange={this.onFieldChange}
                                    error={this.state.errors.categoryId}
                                    isInvalid={this.state.errors.categoryId}
                                    options={this.state.categories.map(
                                        ({id, name}) => ({
                                            value: id,
                                            label: name,
                                        }),
                                    )}
                                />
                                <Input
                                    md='6'
                                    type='select'
                                    label={this.props.intl.formatMessage({
                                        id: 'POSTS.BRAND_LABEL',
                                    })}
                                    name='brandId'
                                    value={this.state.post.brandId}
                                    onChange={this.onFieldChange}
                                    error={this.state.errors.brandId}
                                    isInvalid={this.state.errors.brandId}
                                    options={[
                                        ...this.state.brands.map(
                                            ({id, name}) => ({
                                                value: id,
                                                label: name,
                                            }),
                                        ),
                                        {
                                            value: -1,
                                            label: this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'POSTS.BRAND_OTHER_OPTION',
                                                },
                                            ),
                                        },
                                    ]}
                                />
                                {+this.state.post.brandId === -1 ? (
                                    <Input
                                        md='6'
                                        type='text'
                                        label={this.props.intl.formatMessage({
                                            id: 'POSTS.BRAND_NAME_LABEL',
                                        })}
                                        name='brandName'
                                        value={this.state.post.brandName}
                                        onChange={this.onFieldChange}
                                        error={this.state.errors.brandName}
                                        isInvalid={this.state.errors.brandName}
                                    />
                                ) : null}
                            </Form.Row>
                            <Form.Row>
                                {this.state.attributes.map((attr) => (
                                    <Input
                                        key={attr.id}
                                        md='6'
                                        type='select'
                                        label={attr.name}
                                        name={`attribute${attr.id}`}
                                        value={
                                            this.state.post[
                                                `attribute${attr.id}`
                                            ]
                                        }
                                        onChange={this.onFieldChange}
                                        error={
                                            this.state.errors[
                                                `attribute${attr.id}`
                                            ]
                                        }
                                        isInvalid={
                                            this.state.errors[
                                                `attribute${attr.id}`
                                            ]
                                        }
                                        options={attr.options.map(
                                            ({value, label, group}) => ({
                                                value,
                                                label: `${label}${
                                                    group ? ` (${group})` : ''
                                                }`,
                                            }),
                                        )}
                                    />
                                ))}
                            </Form.Row>
                            <Form.Row>
                                <Input
                                    md='6'
                                    type='text'
                                    label={this.props.intl.formatMessage({
                                        id: 'POSTS.ORIGINAL_PRICE_LABEL',
                                    })}
                                    name='originalPrice'
                                    value={this.state.post.originalPrice}
                                    onChange={this.onFieldChange}
                                    error={this.state.errors.originalPrice}
                                    isInvalid={this.state.errors.originalPrice}
                                />
                                <Input
                                    md='6'
                                    type='text'
                                    label={this.props.intl.formatMessage({
                                        id: 'POSTS.PRICE_LABEL',
                                    })}
                                    name='price'
                                    value={this.state.post.price}
                                    onChange={this.onFieldChange}
                                    error={this.state.errors.price}
                                    isInvalid={this.state.errors.price}
                                />
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Form>
                <div className='text-right'>
                    <LoadingButton
                        variant='primary'
                        className='btn-bold'
                        type='submit'
                        onClick={this.onSubmit}
                        loading={this.state.loading}
                    >
                        {this.props.intl.formatMessage({
                            id: 'USERS.SAVE_BUTTON_TEXT',
                        })}
                    </LoadingButton>
                </div>
            </>
        );
    }
}
export default injectIntl(PostEdit);
