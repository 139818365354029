import React from 'react';
import Form from 'react-bootstrap/Form';
import {Row} from 'react-bootstrap';
import Input from '../components/form/Input';
import ConfirmModal from '../components/form/ConfirmModal';
import LoadingButton from '../components/form/LoadingButton';

class Inputs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            email: '',
            password: '',
            select: '',
            textarea: '',
            checkbox1: false,
            radio: '',
            switch1: false,

            textv: 'Test',
            emailv: 'name@example.com',
            passwordv: 'secreto',
            selectv: '1',
            textareav: 'Test textarea',
            checkbox2: true,
            radio2: 1,
            switch2: true,

            modal: false,

            button: false,
            button2: false,
        };

        this.handleButton = this.handleButton.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleToggleRadio = this.handleToggleRadio.bind(this);
        this.handleToggleRadio2 = this.handleToggleRadio2.bind(this);
        this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
        this.handleToggleCheckBox = this.handleToggleCheckBox.bind(this);
    }

    handleToggleRadio(e) {
        this.setState({radio: e.target.value});
    }

    handleToggleRadio2(e) {
        this.setState({radio2: e.target.value});
    }

    handleToggleSwitch(e) {
        const selectedSwitch = 'switch' + e.target.value;

        if (this.state[selectedSwitch]) {
            this.setState({[selectedSwitch]: false});
        } else {
            this.setState({[selectedSwitch]: true});
        }
    }

    handleModalClose() {
        this.setState({modal: false});
    }

    handleModalShow() {
        this.setState({modal: true});
    }

    handleToggleCheckBox(e) {
        const selectedBox = 'checkbox' + e.target.value;

        if (this.state[selectedBox]) {
            this.setState({[selectedBox]: false});
        } else {
            this.setState({[selectedBox]: true});
        }
    }

    handleFormSubmit(e) {
        e.preventDefault();
        this.setState({button: true});
        setTimeout(() => {
            this.setState({button: false});
        }, 3000);
    }

    handleButton(e) {
        this.setState({button2: true});

        setTimeout(() => {
            this.setState({button2: false});
        }, 3000);
    }

    render() {
        return (
            <>
                <Form>
                    <h1>Examples</h1>
                    <Row>
                        <Input
                            type='text'
                            label='Text'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({text: e.target.value})
                            }
                            value={this.state.text}
                            muted='Example muted text'
                            error='Example error text'
                            md='6'
                            xs='12'
                        ></Input>
                        <Input
                            type='email'
                            label='Email'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({email: e.target.value})
                            }
                            value={this.state.email}
                            muted='Example muted text'
                            error='Example error text'
                        ></Input>
                    </Row>
                    <Row>
                        <Input
                            type='password'
                            label='Password'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({password: e.target.value})
                            }
                            value={this.state.password}
                            muted='Example muted text'
                            error='Example error text'
                        ></Input>
                        <Input
                            type='select'
                            label='Select'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({select: e.target.value})
                            }
                            value={this.state.select}
                            muted='Example muted text'
                            error='Example error text'
                            options={data.options}
                        ></Input>
                    </Row>
                    <Row>
                        <Input
                            type='textarea'
                            label='Textarea'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({textarea: e.target.value})
                            }
                            value={this.state.textarea}
                            muted='Example muted text'
                            error='Example error text'
                        ></Input>
                        <Input
                            type='radio'
                            label='Radiobutton'
                            placeholder='Radiobutton'
                            muted='Example muted text'
                            error='Example error text'
                            onChange={this.handleToggleRadio}
                            radios={data.radios}
                            value={this.state.radio}
                        ></Input>
                        <Input
                            type='checkbox'
                            label='Checkbox'
                            placeholder='Checkbox'
                            muted='Example muted text'
                            error='Example error text'
                            onChange={this.handleToggleCheckBox}
                            checked={this.state.checkbox1}
                            value='1'
                        ></Input>
                        <Input
                            type='switch'
                            label='Switch'
                            id='Switch1'
                            placeholder='Switch'
                            muted='Example muted text'
                            error='Example error text'
                            onChange={this.handleToggleSwitch}
                            checked={this.state.switch1}
                            value='1'
                        ></Input>
                    </Row>

                    <h1>Examples with value</h1>
                    <Row>
                        <Input
                            type='text'
                            label='Text'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({textv: e.target.value})
                            }
                            value={this.state.textv}
                            muted='Example muted text'
                            error='Example error text'
                            md='6'
                            xs='12'
                        ></Input>
                        <Input
                            type='email'
                            label='Email'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({emailv: e.target.value})
                            }
                            value={this.state.emailv}
                            muted='Example muted text'
                            error='Example error text'
                        ></Input>
                    </Row>
                    <Row>
                        <Input
                            type='password'
                            label='Password'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({passwordv: e.target.value})
                            }
                            value={this.state.passwordv}
                            muted='Example muted text'
                            error='Example error text'
                        ></Input>
                        <Input
                            type='select'
                            label='Select'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({selectv: e.target.value})
                            }
                            value={this.state.selectv}
                            muted='Example muted text'
                            error='Example error text'
                            options={data.options}
                        ></Input>
                    </Row>
                    <Row>
                        <Input
                            type='textarea'
                            label='Textarea'
                            placeholder='Example'
                            onChange={(e) =>
                                this.setState({textareav: e.target.value})
                            }
                            value={this.state.textareav}
                            muted='Example muted text'
                            error='Example error text'
                        ></Input>
                        <Input
                            type='radio'
                            label='Radiobutton'
                            placeholder='Radiobutton'
                            muted='Example muted text'
                            error='Example error text'
                            onChange={this.handleToggleRadio2}
                            radios={data.radios2}
                            value={this.state.radio2}
                        ></Input>
                        <Input
                            type='checkbox'
                            label='Checkbox'
                            placeholder='Checkbox'
                            muted='Example muted text'
                            error='Example error text'
                            onChange={this.handleToggleCheckBox}
                            checked={this.state.checkbox2}
                            value='2'
                        ></Input>
                        <Input
                            type='switch'
                            label='Switch'
                            id='Switch2'
                            placeholder='Switch'
                            muted='Example muted text'
                            error='Example error text'
                            onChange={this.handleToggleSwitch}
                            checked={this.state.switch2}
                            value='2'
                        ></Input>
                    </Row>
                </Form>
                <h1>Modals</h1>
                <ConfirmModal
                    buttonLabel='Eliminar'
                    variant='danger'
                    titleModal='Eliminar item'
                    show={this.state.modal}
                    handleShow={this.handleModalShow}
                    handleClose={this.handleModalClose}
                    handleConfirm={this.handleModalClose}
                    buttonCloseLabel='Cancelar'
                    buttonAcceptLabel='Sí, eliminar'
                >
                    <p className='text-danger'>¿Deseas eliminar el item?</p>
                </ConfirmModal>
                <h1>Loading buttons</h1>
                <Form onSubmit={this.handleFormSubmit}>
                    <LoadingButton
                        label='Submit'
                        type='submit'
                        variant='success'
                        size='lg'
                        frontColor='white'
                        backColor='rgba(255,255,255,0.35)'
                        loading={this.state.button}
                        block
                    ></LoadingButton>
                </Form>
                <LoadingButton
                    label='Button'
                    type='button'
                    variant='primary'
                    size='md'
                    frontColor='white'
                    backColor='rgba(255,255,255,0.35)'
                    onClick={this.handleButton}
                    loading={this.state.button2}
                    block
                ></LoadingButton>
            </>
        );
    }
}

const data = {
    options: [
        {
            label: 'Option1',
            value: '1',
        },
        {
            label: 'Option2',
            value: '2',
        },
    ],
    radios: [
        {
            id: 'Radio1',
            label: 'Radio1',
            value: '1',
        },
        {
            id: 'Radio2',
            label: 'Radio2',
            value: '2',
        },
        {
            id: 'Radio3',
            label: 'Radio3',
            value: '3',
        },
    ],
    radios2: [
        {
            id: 'Radio4',
            label: 'Radio1',
            value: '1',
        },
        {
            id: 'Radio5',
            label: 'Radio2',
            value: '2',
        },
        {
            id: 'Radio6',
            label: 'Radio3',
            value: '3',
        },
    ],
};

export default Inputs;
