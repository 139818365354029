import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Modal from 'react-bootstrap/Modal';
import {Row, Col} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import * as FeatherIcons from 'react-icons/fi';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper';

import Input from '../../components/form/Input';
import API from '../../common/utils/API';
import Post from '../../common/models/Post';
import Can from '../../common/security/Can';
import {PostStatus} from '../../common/config/constants';

SwiperCore.use([Navigation, Pagination]);

class PostDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post: new Post(),
            comments: [],
            error: '',
            openComments: false,
            openAprove: false,
            openAdjustment: false,
            openReject: false,
            openActive: false,
            reviewComments: '',
            loading: false,
        };
    }

    componentDidMount = async () => {
        await this.fetchItem();
    };

    fetchItem = async () => {
        try {
            const [{post}, {comments}] = await Promise.all([
                API.posts.detail(this.props.match.params.id),
                API.posts.comments(this.props.match.params.id),
            ]);
            this.setState({
                post: post,
                comments: comments,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
            });
        }
    };

    reviewItem = async (status) => {
        this.setState({
            loading: true,
        });
        try {
            const {post} = await API.posts.review(
                this.props.match.params.id,
                status,
                this.state.reviewComments,
            );
            this.setState({
                post: post,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
            });
        }
        this.setState({
            loading: false,
            openAprove: false,
            openAdjustment: false,
            openReject: false,
            openActive: false,
            reviewComments: '',
        });
    };

    togglePost = async () => {
        this.setState({
            loading: true,
        });

        try {
            const {isActive} = await API.posts.toggle(
                this.props.match.params.id,
            );
            const post = {...this.state.post};

            post.isActive = isActive;

            this.setState({post});
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
            });
        }

        this.setState({
            loading: false,
            openAprove: false,
            openAdjustment: false,
            openReject: false,
            openActive: false,
            reviewComments: '',
        });
    };

    toggleModal = (modal, show = false) => {
        this.setState({
            [modal]: show,
            reviewComments: '',
        });
    };

    onChange = (event) => {
        this.setState({
            reviewComments: event.target.value,
        });
    };

    formatDate = (s) => {
        const date = new Date(s);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Can run='POSTS_EDIT'>
                        <Button
                            as={NavLink}
                            to={`/posts/${this.props.match.params.id}/edit`}
                            className='btn-info btn-bold mx-2'
                        >
                            {this.props.intl.formatMessage({
                                id: 'POSTS.EDIT_BUTTON_TEXT',
                            })}
                        </Button>
                    </Can>
                    <Button
                        as={NavLink}
                        to='/posts'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'POSTS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.post.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.CREATED_LABEL',
                                    })}
                                </label>
                                <p>{this.state.post.formatDate}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.post.name}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.DESCRIPTION_LABEL',
                                    })}
                                </label>
                                <p>{this.state.post.description}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.USER_LABEL',
                                    })}
                                </label>
                                <p>{this.state.post.userName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.LOCATION_LABEL',
                                    })}
                                </label>
                                <p>{this.state.post.location}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.CATEGORY_LABEL',
                                    })}
                                </label>
                                <p>{this.state.post.category.name}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.BRAND_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.post.brand.name}{' '}
                                    {!this.state.post.brand.id && (
                                        <span>
                                            (
                                            {this.props.intl.formatMessage({
                                                id: 'POSTS.NEW_BRAND_MESSAGE',
                                            })}
                                            )
                                        </span>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.post.attributes.map((attr) => (
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {attr.label}
                                    </label>
                                    <p>{attr.value}</p>
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.PRICE_LABEL',
                                    })}
                                </label>
                                <p>${this.state.post.price}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.ORIGINAL_PRICE_LABEL',
                                    })}
                                </label>
                                <p>${this.state.post.originalPrice}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.AVAILABLE_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.post.quantityAvailable > 0
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : this.props.intl.formatMessage({
                                              id: 'GENERAL.NO',
                                          })}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.CENSORED_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.post.isCensored
                                        ? this.props.intl.formatMessage({
                                              id: 'GENERAL.YES',
                                          })
                                        : this.props.intl.formatMessage({
                                              id: 'GENERAL.NO',
                                          })}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'POSTS.STATUS_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: `POSTS.STATUS_${this.state.post.status.toUpperCase()}`,
                                    })}
                                </p>
                            </Col>
                            <Can run='POSTS_REVIEW'>
                                {[
                                    PostStatus.PENDING,
                                    PostStatus.ADJUSTMENT,
                                ].includes(this.state.post.status) &&
                                this.state.post.id ? (
                                    <Col sm={12} className='text-right'>
                                        <Button
                                            variant='success'
                                            className='ml-2 mb-2'
                                            onClick={() =>
                                                this.toggleModal(
                                                    'openAprove',
                                                    true,
                                                )
                                            }
                                        >
                                            {this.props.intl.formatMessage({
                                                id: 'POSTS.ACTION_APROVE',
                                            })}
                                        </Button>
                                        <Button
                                            variant='warning'
                                            className='ml-2 mb-2'
                                            onClick={() =>
                                                this.toggleModal(
                                                    'openAdjustment',
                                                    true,
                                                )
                                            }
                                        >
                                            {this.props.intl.formatMessage({
                                                id: 'POSTS.ACTION_ADJUSTMENT',
                                            })}
                                        </Button>
                                        <Button
                                            variant='danger'
                                            className='ml-2 mb-2'
                                            onClick={() =>
                                                this.toggleModal(
                                                    'openReject',
                                                    true,
                                                )
                                            }
                                        >
                                            {this.props.intl.formatMessage({
                                                id: 'POSTS.ACTION_REJECT',
                                            })}
                                        </Button>
                                    </Col>
                                ) : (
                                    this.state.post.id && (
                                        <Col sm={12} className='text-right'>
                                            <Button
                                                variant={
                                                    !this.state.post?.isActive
                                                        ? 'success'
                                                        : 'danger'
                                                }
                                                className='ml-2 mb-2'
                                                onClick={() =>
                                                    this.toggleModal(
                                                        'openActive',
                                                        true,
                                                    )
                                                }
                                            >
                                                {!this.state.post?.isActive
                                                    ? this.props.intl.formatMessage(
                                                          {
                                                              id:
                                                                  'POSTS.ACTION_ACTIVATE',
                                                          },
                                                      )
                                                    : this.props.intl.formatMessage(
                                                          {
                                                              id:
                                                                  'POSTS.ACTION_DEACTIVATE',
                                                          },
                                                      )}
                                            </Button>
                                        </Col>
                                    )
                                )}
                            </Can>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'POSTS.PICTURES_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Swiper
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                480: {
                                    slidesPerView: 2,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                            }}
                            spaceBetween={12}
                            navigation
                            pagination={{clickable: true}}
                        >
                            {Object.entries(this.state.post.pictures).map(
                                ([key, url]) => (
                                    <SwiperSlide key={key}>
                                        <img
                                            src={url}
                                            alt={key}
                                            className='img-fluid'
                                        />
                                    </SwiperSlide>
                                ),
                            )}
                        </Swiper>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header
                        onClick={() =>
                            this.setState({
                                openComments: !this.state.openComments,
                            })
                        }
                    >
                        {this.props.intl.formatMessage({
                            id: 'POSTS.COMMENTS_SECTION_TITLE',
                        })}
                        {this.state.openComments ? (
                            <FeatherIcons.FiChevronLeft
                                className='float-right'
                                size={20}
                            />
                        ) : (
                            <FeatherIcons.FiChevronDown
                                className='float-right'
                                size={20}
                            />
                        )}
                    </Card.Header>
                    <Collapse in={this.state.openComments}>
                        <Card.Body>
                            {this.state.comments.map((comment, i) => (
                                <>
                                    <Row>
                                        <Col xs={8}>
                                            <Alert variant={'primary'}>
                                                <div className='mb-2 clearfix'>
                                                    <strong>
                                                        {comment.user.firstName}
                                                    </strong>
                                                    <span className='float-right'>
                                                        {this.formatDate(
                                                            comment.createdAt,
                                                        )}
                                                    </span>
                                                </div>
                                                {comment.text}
                                            </Alert>
                                        </Col>
                                        {comment.answers.map((answer) => (
                                            <Col xs={{span: 8, offset: 4}}>
                                                <Alert variant={'secondary'}>
                                                    <div className='mb-2 clearfix'>
                                                        <strong>
                                                            {
                                                                answer.user
                                                                    .firstName
                                                            }
                                                        </strong>
                                                        <span className='float-right'>
                                                            {this.formatDate(
                                                                answer.createdAt,
                                                            )}
                                                        </span>
                                                    </div>
                                                    {answer.text}
                                                </Alert>
                                            </Col>
                                        ))}
                                    </Row>
                                </>
                            ))}
                        </Card.Body>
                    </Collapse>
                </Card>
                <Modal
                    show={this.state.openAprove}
                    onHide={() => this.toggleModal('openAprove', false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'POSTS.ACTION_APROVE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    {!this.state.post.brand.id && (
                        <Modal.Body>
                            {this.props.intl.formatMessage({
                                id: 'POSTS.NEW_BRAND_MODAL_MESSAGE',
                            })}
                        </Modal.Body>
                    )}
                    <Modal.Footer>
                        <Button
                            variant='outline-secondary'
                            onClick={() =>
                                this.toggleModal('openAprove', false)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.CANCEL',
                            })}
                        </Button>
                        <Button
                            variant='success'
                            disabled={this.state.loading}
                            onClick={() => this.reviewItem(PostStatus.APPROVED)}
                        >
                            {this.props.intl.formatMessage({
                                id: 'POSTS.ACTION_DO_APROVE',
                            })}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.openAdjustment}
                    onHide={() => this.toggleModal('openAdjustment', false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'POSTS.ACTION_ADJUSTMENT',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input
                            type='textarea'
                            label={this.props.intl.formatMessage({
                                id: 'POSTS.REVIEW_COMMENTS_LABEL',
                            })}
                            value={this.state.reviewComments}
                            onChange={this.onChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='outline-secondary'
                            onClick={() =>
                                this.toggleModal('openAdjustment', false)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.CANCEL',
                            })}
                        </Button>
                        <Button
                            variant='warning'
                            disabled={this.state.loading}
                            onClick={() =>
                                this.reviewItem(PostStatus.ADJUSTMENT)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'POSTS.ACTION_DO_ADJUSTMENT',
                            })}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.openReject}
                    onHide={() => this.toggleModal('openReject', false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'POSTS.ACTION_REJECT',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button
                            variant='outline-secondary'
                            onClick={() => this.reviewItem(PostStatus.REJECTED)}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.CANCEL',
                            })}
                        </Button>
                        <Button
                            variant='danger'
                            disabled={this.state.loading}
                            onClick={() =>
                                this.toggleModal('openReject', false)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'POSTS.ACTION_DO_REJECT',
                            })}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.openActive}
                    onHide={() => this.toggleModal('openActive', false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {!this.state.post?.isActive
                                ? this.props.intl.formatMessage({
                                      id: 'POSTS.ACTION_ACTIVATE',
                                  })
                                : this.props.intl.formatMessage({
                                      id: 'POSTS.ACTION_DEACTIVATE',
                                  })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button
                            variant='outline-secondary'
                            onClick={() =>
                                this.toggleModal('openActive', false)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.CANCEL',
                            })}
                        </Button>
                        <Button
                            variant='success'
                            disabled={this.state.loading}
                            onClick={() => this.togglePost()}
                        >
                            {!this.state.post?.isActive
                                ? this.props.intl.formatMessage({
                                      id: 'POSTS.ACTION_DO_ACTIVATE',
                                  })
                                : this.props.intl.formatMessage({
                                      id: 'POSTS.ACTION_DO_DEACTIVATE',
                                  })}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(PostDetail);
