import React from 'react';
import {NavLink} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper';

import Return from '../../common/models/Return';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import LoadingButton from '../../components/form/LoadingButton';
import {ReturnStatus} from '../../common/config/constants';

SwiperCore.use([Navigation, Pagination]);

class ReturnDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            returnOrder: new Return(),
            error: '',
            isRefundOpen: false,
            loading: false,
            success: false,
        };
    }

    async componentDidMount() {
        return this.fetchData();
    }

    fetchData = async () => {
        this.setState({loading: true, error: ''});
        try {
            const {returnOrder} = await API.returns.detail(
                this.props.match.params.id,
            );
            this.setState({
                returnOrder,
                loading: false,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
            });
        }
    };

    doRefund = async () => {
        this.setState({loading: true, error: '', success: false});
        try {
            const {returnOrder} = await API.returns.refund(
                this.props.match.params.id,
            );
            this.setState({
                returnOrder,
                loading: false,
                isRefundOpen: false,
                success: true,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
                isRefundOpen: false,
            });
        }
    };

    toggleRefundModal = () => {
        this.setState({
            isRefundOpen: !this.state.isRefundOpen,
        });
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <Alert
                    variant='success'
                    className='mb-4'
                    show={this.state.success}
                    dismissible={true}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'RETURNS.REFUND_MESSAGE_SUCCESS',
                        })}
                    </p>
                </Alert>
                <div className='mb-4 text-right'>
                    <Can run='RETURNS_CONFIRM'>
                        {this.state.returnOrder.status ===
                        ReturnStatus.PENDING ? (
                            <Button
                                as={NavLink}
                                to={`/returns/${this.state.returnOrder.id}/confirm`}
                                className='btn btn-dark btn-bold btn-light-dark mx-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'RETURNS.CONFIRM_BUTTON',
                                })}
                            </Button>
                        ) : null}
                    </Can>
                    <Can run='RETURNS_REFUND'>
                        {this.state.returnOrder.refundable ? (
                            <Button
                                className='btn-info btn-bold mx-2 btn'
                                onClick={this.toggleRefundModal}
                            >
                                {this.props.intl.formatMessage({
                                    id: 'RETURNS.REFUND_TITLE',
                                })}
                            </Button>
                        ) : null}
                    </Can>
                    <Button
                        as={NavLink}
                        to='/returns'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card data-test='detailOrderComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'RETURNS.GENERAL_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'RETURNS.ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.returnOrder.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'RETURNS.DATE_OF_RETURN_REQUEST_TABLE_HEADER',
                                    })}
                                </label>
                                <p>{this.state.returnOrder.formatDate}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'RETURNS.ORDER_ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.returnOrder.order.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'RETURNS.DATE_OF_PURCHASE_TABLE_HEADER',
                                    })}
                                </label>
                                <p>{this.state.returnOrder.formatOrderDate}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'RETURNS.BUYER_TABLE_HEADER',
                                    })}
                                </label>
                                <p>{this.state.returnOrder.buyerFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'RETURNS.SELLER_TABLE_HEADER',
                                    })}
                                </label>
                                <p>{this.state.returnOrder.sellerFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'RETURNS.STATUS_TABLE_HEADER',
                                    })}
                                </label>
                                <p>
                                    {this.state.returnOrder.status
                                        ? this.props.intl.formatMessage({
                                              id: `RETURNS.STATUS_${this.state.returnOrder.status.toUpperCase()}`,
                                          })
                                        : ''}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'RETURNS.TOTAL_LABEL',
                                    })}
                                </label>
                                <p>{this.state.returnOrder.total}</p>
                            </Col>
                            {this.state.returnOrder.fees.map((fee) => (
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {fee?.name}
                                    </label>
                                    <p>${fee?.amount?.toFixed(2) || 0}</p>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card>
                <Card data-test='detailOrderComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'RETURNS.DETAILS_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        {this.state.returnOrder.details.map((detail) => (
                            <Row key={detail.id}>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.NAME_LABEL',
                                        })}
                                    </label>
                                    <p>{detail.name}</p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.PRICE_LABEL',
                                        })}
                                    </label>
                                    <p>{detail.price}</p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.REASON_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {detail.reason
                                            ? this.props.intl.formatMessage({
                                                  id: `RETURNS.REASON_${detail.reason.toUpperCase()}`,
                                              })
                                            : ''}
                                    </p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'RETURNS.DETAIL_BUYER_COMMENTS_LABEL',
                                        })}
                                    </label>
                                    <p>{detail.comments}</p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.DETAIL_STATUS_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {detail.status
                                            ? this.props.intl.formatMessage({
                                                  id: `RETURNS.STATUS_${detail.status.toUpperCase()}`,
                                              })
                                            : ''}
                                    </p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'RETURNS.DETAIL_REVIEW_COMMENTS_LABEL',
                                        })}
                                    </label>
                                    <p>{detail.reviewComments}</p>
                                </Col>
                                <hr />
                                <Col sm={12}>
                                    <Swiper
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                            },
                                            480: {
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            1024: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                        spaceBetween={12}
                                        navigation
                                        pagination={{clickable: true}}
                                    >
                                        {detail.images?.map((url, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={url}
                                                    alt={url}
                                                    className='img-fluid'
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Col>
                            </Row>
                        ))}
                    </Card.Body>
                </Card>
                <Card data-test='detailOrderComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'RETURNS.SHIPMENT_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        {this.state.returnOrder.shipment.id ? (
                            <Row>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.CARRIER_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {
                                            this.state.returnOrder.shipment
                                                .carrierName
                                        }
                                    </p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.TRACKING_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {
                                            this.state.returnOrder.shipment
                                                .trackingNumber
                                        }
                                    </p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.SHIPMENT_STATUS_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {this.state.returnOrder.shipment.status
                                            ? this.props.intl.formatMessage({
                                                  id: `ORDERS.SHIPMENT_STATUS_${this.state.returnOrder.shipment.status.toUpperCase()}`,
                                              })
                                            : ''}
                                    </p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.DELIVERED_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {
                                            this.state.returnOrder
                                                .formatDeliveryDate
                                        }
                                    </p>
                                </Col>
                            </Row>
                        ) : (
                            <h6 className='text-center text-muted'>
                                {this.props.intl.formatMessage({
                                    id: 'RETURNS.NO_SHIPMENT',
                                })}
                            </h6>
                        )}
                    </Card.Body>
                </Card>
                <Card data-test='detailOrderComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'RETURNS.PAYMENT_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        {this.state.returnOrder.payment.id ? (
                            <Row>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.REFUND_ID_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {
                                            this.state.returnOrder.payment
                                                .paymentId
                                        }
                                    </p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.REFUND_AMOUNT_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {this.state.returnOrder.payment.amount}
                                    </p>
                                </Col>
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'RETURNS.REFUNDED_AT_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {
                                            this.state.returnOrder
                                                .formatRefundDate
                                        }
                                    </p>
                                </Col>
                            </Row>
                        ) : (
                            <h6 className='text-center text-muted'>
                                {this.props.intl.formatMessage({
                                    id: 'RETURNS.NO_PAYMENT',
                                })}
                            </h6>
                        )}
                    </Card.Body>
                </Card>
                <Modal show={this.state.isRefundOpen}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'RETURNS.REFUND_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'RETURNS.REFUND_MESSAGE',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.toggleRefundModal}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.CANCEL',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.doRefund}
                        >
                            {this.props.intl.formatMessage({
                                id: 'RETURNS.REFUND_ACTION',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(ReturnDetail);
