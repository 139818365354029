import React from 'react';
import {Redirect, NavLink} from 'react-router-dom';

import API from '../../common/utils/API';

import Input from '../../components/form/Input';
import LoadingButton from '../../components/form/LoadingButton';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Role from '../../common/models/Role';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import ConfirmModal from '../../components/form/ConfirmModal';
import {injectIntl} from 'react-intl';
import Can from '../../common/security/Can';

class EditRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            role: new Role(),
            error: '',
            errors: {},
            permissions: [],
            permissionGroups: [],
            isDeleteRoleModalOpen: false,
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
        };
    }

    componentDidMount = () => {
        this.loadRole();
        this.loadPermissions();
    };

    loadPermissions = () => {
        API.permissions
            .get()
            .then((permissions) => {
                let groups = this.state.permissionGroups;
                groups = groups.concat(
                    permissions.map((permission) => permission.group),
                );

                this.setState({
                    permissionGroups: groups,
                    permissions: permissions,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadErrorModalOpen: true,
                });
            });
    };

    loadRole = () => {
        this.setState({
            loading: true,
        });
        API.roles
            .detail(this.props.match.params.id)
            .then((response) => {
                let groups = this.state.permissionGroups;
                groups = groups.concat(
                    response.role.permissions.map(
                        (permission) => permission.group,
                    ),
                );
                this.setState({
                    role: response.role,
                    isLoadErrorModalOpen: false,
                    permissionGroups: groups,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadErrorModalOpen: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    validateForm = () => {
        // Validate email
        let result = true;
        const errors = this.state.errors;

        if (this.state.role.name.trim().length === 0) {
            result = false;
            errors.name = this.props.intl.formatMessage({
                id: 'ROLES.VALIDATION.ROLE_NAME_MISSING',
            });
        } else {
            delete errors.name;
        }

        this.setState({
            errors,
        });

        return result;
    };

    handleNameChange = (e) => {
        if (this.state.errors.name) {
            this.validateForm();
        }
        const role = this.state.role;
        role.name = e.target.value;
        this.setState({
            role: role,
        });
    };

    handleUpdateRoleFormSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({
                loading: true,
            });
            const role = {
                name: this.state.role.name,
                permissions: this.state.role.permissions.map(
                    (permission) => permission.code,
                ),
            };
            API.roles
                .update(this.props.match.params.id, role)
                .then((response) => {
                    this.setState({
                        shouldRedirectToDetail: true,
                        redirectSuccessMessage: this.props.intl.formatMessage({
                            id: 'ROLES.ROLE_SUCCESSFULLY_UPDATED_MESSAGE',
                        }),
                    });
                })
                .catch((e) => {
                    if (e.response && e.response.status === 422) {
                        this.setState({
                            errors: e.response.data.messages,
                        });
                    }
                    this.setState({
                        error: {},
                        loading: false,
                    });
                });
        }
    };

    selectAllPemissions = () => {
        const permisions = [...this.state.permissions];
        const role = this.state.role;
        role.permissions = permisions;
        this.setState({
            role,
        });
    };

    deselectAllPermisions = () => {
        const role = this.state.role;
        role.permissions = [];
        this.setState({
            role,
        });
    };

    handlePermissionToggle = (e, permission) => {
        const role = this.state.role;
        let permissions = role.permissions;
        if (
            permissions.find(
                (selectedPermission) =>
                    permission.code === selectedPermission.code,
            )
        ) {
            permissions = permissions.filter(
                (selectedPermission) =>
                    permission.code !== selectedPermission.code,
            );
        } else {
            permissions.push(permission);
        }
        role.permissions = permissions;
        this.setState({
            role,
        });
    };

    handleDeleteRole = (e) => {
        API.roles
            .delete(this.props.match.params.id)
            .then((response) => {
                this.setState({
                    shouldRedirectToIndex: true,
                    redirectSuccessMessage: this.props.intl.formatMessage({
                        id: 'ROLES.ROLE_SUCCESSFULLY_DELETED_MESSAGE',
                    }),
                });
            })
            .catch((e) => {
                this.setState({
                    isDeleteRoleModalOpen: false,
                    error: {},
                    loading: false,
                });
            });
    };

    render() {
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                to={{
                    pathname: `/roles/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/roles',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={this.state.error ? true : false}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.VALIDATION.GENERIC_ERROR',
                        })}
                    </p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to={`/roles/${this.props.match.params.id}`}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'ROLES.BACK_TO_ROLE_DETAIL',
                        })}
                    </Button>
                </div>
                <Form onSubmit={this.handleUpdateRoleFormSubmit}>
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ROLES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Input
                                    type='text'
                                    label={this.props.intl.formatMessage({
                                        id: 'ROLES.ROLE_NAME_FIELD_LABEL',
                                    })}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'ROLES.ROLE_NAME_FIELD_PLACEHOLDER',
                                    })}
                                    value={this.state.role.name}
                                    onChange={this.handleNameChange}
                                    error={this.state.errors.name}
                                    isInvalid={this.state.errors.name}
                                />
                            </Form.Row>
                        </Card.Body>
                    </Card>
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ROLES.PRIVILEGE_PROFILE_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body className='p-0'>
                            <div className='p-3 text-right'>
                                <Button
                                    className='mr-3'
                                    variant='secondary'
                                    onClick={this.deselectAllPermisions}
                                >
                                    Deseleccionar todo
                                </Button>
                                <Button
                                    variant='primary'
                                    onClick={this.selectAllPemissions}
                                >
                                    Seleccionar todo
                                </Button>
                            </div>
                            <Table className='mb-0'>
                                <tbody>
                                    {this.state.permissionGroups
                                        .filter((x, i, a) => a.indexOf(x) === i)
                                        .sort()
                                        .map((group, key) => (
                                            <tr key={key}>
                                                <td>{group}</td>
                                                <td>
                                                    {this.state.permissions
                                                        .filter(
                                                            (permission) =>
                                                                permission.group ===
                                                                group,
                                                        )
                                                        .map(
                                                            (
                                                                permission,
                                                                key,
                                                            ) => (
                                                                <Form.Row
                                                                    key={key}
                                                                >
                                                                    <Form.Check
                                                                        type='switch'
                                                                        id={
                                                                            permission.code
                                                                        }
                                                                        label={
                                                                            permission.description
                                                                        }
                                                                        checked={this.state.role.permissions.find(
                                                                            (
                                                                                rolePermission,
                                                                            ) =>
                                                                                rolePermission.code ===
                                                                                permission.code,
                                                                        )}
                                                                        value={
                                                                            permission.code
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            this.handlePermissionToggle(
                                                                                e,
                                                                                permission,
                                                                            );
                                                                        }}
                                                                    />
                                                                </Form.Row>
                                                            ),
                                                        )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <Can run='ROLES_DELETE'>
                                {this.state.role.userCount > 0 ? (
                                    <Button
                                        variant='danger'
                                        className='btn-bold'
                                        disabled='disabled'
                                    >
                                        {this.props.intl.formatMessage({
                                            id:
                                                'ROLES.DELETE_ROLE_BUTTON_LABEL',
                                        })}
                                    </Button>
                                ) : (
                                    <ConfirmModal
                                        titleModal={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'ROLES.DELETE_ROLE_MODAL_TITLE',
                                            },
                                        )}
                                        handleConfirm={this.handleDeleteRole}
                                        handleShow={() =>
                                            this.setState({
                                                isDeleteRoleModalOpen: true,
                                            })
                                        }
                                        handleClose={() =>
                                            this.setState({
                                                isDeleteRoleModalOpen: false,
                                            })
                                        }
                                        show={this.state.isDeleteRoleModalOpen}
                                        variant='danger'
                                        buttonClassName='btn-bold'
                                        buttonLabel={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'ROLES.DELETE_ROLE_BUTTON_LABEL',
                                            },
                                        )}
                                        buttonAcceptLabel={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'ROLES.CONFIRM_DELETE_BUTTON_LABEL',
                                            },
                                        )}
                                        buttonCloseLabel={this.props.intl.formatMessage(
                                            {
                                                id:
                                                    'ROLES.CANCEL_DELETE_BUTTON_LABEL',
                                            },
                                        )}
                                    >
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ROLES.DELETE_ROLE_CONFIRMATION_MESSAGE',
                                            })}
                                        </p>
                                    </ConfirmModal>
                                )}
                            </Can>
                        </div>
                        <div>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                loading={this.state.loading}
                            >
                                {this.props.intl.formatMessage({
                                    id: 'ROLES.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </Form>
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadRole}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default injectIntl(EditRole);
