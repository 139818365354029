import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Notification from '../../models/Notification';

const notifications = {
    get: async (page = 1) => {
        const {data: response} = await API.get(
            `notifications?admin=true&page=${page}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map?.((i) => new Notification(i)) || [];
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    read: async (id) => {
        try {
            await API.put(`/notifications/${id}/read`, {admin: true});
            return {succcess: true};
        } catch (error) {
            return {success: false};
        }
    },
};

export default notifications;
