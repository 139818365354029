export const PostStatus = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    ADJUSTMENT: 'adjustment',
};

export const PostCondition = {
    NEW_WITH_TAG: 'new_with_tag',
    NEW_WITHOUT_TAG: 'new_whitout_tag',
    USED: 'used',
};

export const GarmentColors = {
    WHITE: 'white',
    BEIGE: 'beige',
    GRAY: 'gray',
    BLUE: 'blue',
    PINK: 'pink',
    YELLOW: 'yellow',
    ORANGE: 'orange',
    BLACK: 'black',
    BROWN: 'brown',
    GREEN: 'green',
    RED: 'red',
    PURPLE: 'purple',
    GOLDEN: 'golden',
    SILVER: 'silver',
    PATTERN: 'pattern',
};

export const GarmentTypes = {
    BAGS: 'bags',
    CHILDREN: 'children',
    DRESSES: 'dresses',
};

export const ReportReason = {
    ANIMAL: 'animal',
    WEAPON: 'weapon',
    SEXUAL: 'sexual',
    DISCRIMINATE: 'discriminate',
    FAKE: 'fake',
    FRAUD: 'fraud',
    OTHER: 'other',
};

export const ReportStatus = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
};

export const ShipmentStatus = {
    PENDING: 'pending',
    DOCUMENTED: 'OC',
    IN_TRANSIT: 'IT',
    DELAYED: 'DY',
    EXCEPTION: 'DE',
    DELIVERED: 'DL',
};

export const ReturnStatus = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    PARTIAL: 'partial',
    PENDING: 'pending',
};

export const ReturnDetailStatus = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    PENDING: 'pending',
};

export const OrderStatus = {
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    MISSED: 'missed',
};

export const OrderCaseTopic = {
    TRACK_NUMBER: 'track_number',
    ORDER_NUMBER: 'order_number',
    DELAYED: 'delayed',
    OVERCHARGE: 'overcharge',
    IDLE: 'idle',
    OTHER: 'other',
};

export const OrderCaseStatus = {
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    CLOSED: 'closed',
};

export const SupportStatus = {
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    CLOSED: 'closed',
};

export default {
    PostStatus: PostStatus,
    PostCondition: PostCondition,
    GarmentColor: GarmentColors,
    GarmentType: GarmentTypes,
    ShipmentStatus: ShipmentStatus,
    OrderStatus: OrderStatus,
    SupportStatus: SupportStatus,
};
