import React from 'react';
import {injectIntl} from 'react-intl';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {NavLink} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Can from '../../common/security/Can';
import LoadingButton from '../../components/form/LoadingButton';
import API from '../../common/utils/API';
import OrdersReports from '../../common/models/OrdersReports';
import ReturnMoneyForm from '../../components/form/orders_reports/ReturnMoneyForm';
import SendEmailForm from '../../components/form/orders_reports/SendEmailForm';
import CloseReportForm from '../../components/form/orders_reports/CloseReportForm';
import {OrderCaseStatus} from '../../common/config/constants';

export class OrderReportDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderReport: new OrdersReports(),
            error: '',
            isLoadErrorModalOpen: false,
            isReturnPaymentModalOpen: false,
            isSendEmailModalOpen: false,
            isCloseReportModalOpen: false,
            doneMessage: '',
            loading: false,
        };
    }

    componentDidMount() {
        return this.fetchOrderReport();
    }

    fetchOrderReport = async () => {
        this.setState({loading: true});
        try {
            const {orderReport} = await API.ordersReports.detail(
                this.props.match.params.id,
            );

            this.setState({
                orderReport,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
                isLoadErrorModalOpen: true,
            });
        }
    };

    onSubmit = async (input, action) => {
        this.setState({loading: true});
        let orderReport = {...this.state.orderReport};
        let doneMessage = '';

        try {
            switch (action) {
                case 'returnMoney':
                    const {order} = await API.ordersReports.refund(
                        input,
                        orderReport.order.id,
                    );

                    orderReport.payments = order.payments;
                    orderReport.payable = order.payable;
                    doneMessage = this.props.intl.formatMessage({
                        id: 'ORDERS_REPORTS.RETURN_MONEY_DONE',
                    });
                    break;
                case 'closeReport':
                    input.status = OrderCaseStatus.CLOSED;

                    ({orderReport} = await API.ordersReports.review(
                        input,
                        this.props.match.params.id,
                    ));
                    doneMessage = this.props.intl.formatMessage({
                        id: 'ORDERS_REPORTS.CLOSE_REPORT_DONE',
                    });
                    break;
                case 'sendEmail':
                    input.status = OrderCaseStatus.IN_PROGRESS;
                    ({orderReport} = await API.ordersReports.review(
                        input,
                        this.props.match.params.id,
                    ));
                    doneMessage = this.props.intl.formatMessage({
                        id: 'ORDERS_REPORTS.SEND_EMAIL_DONE',
                    });
                    break;
                default:
                    return;
            }

            this.setState(
                {
                    loading: false,
                    orderReport,
                    doneMessage,
                    isReturnPaymentModalOpen: false,
                    isSendEmailModalOpen: false,
                    isCloseReportModalOpen: false,
                },
                () => this.cleanAlertsMessages('doneMessage'),
            );
        } catch (e) {
            this.setState(
                {
                    loading: false,
                    error: this.props.intl.formatMessage({
                        id: 'ORDERS_REPORTS.SUBMIT_ERROR',
                    }),
                    isReturnPaymentModalOpen: false,
                    isSendEmailModalOpen: false,
                    isCloseReportModalOpen: false,
                },
                () => this.cleanAlertsMessages('error'),
            );
        }
    };

    toggleReturnPaymentModal = () => {
        this.setState({
            isReturnPaymentModalOpen: !this.state.isReturnPaymentModalOpen,
        });
    };

    toggleSendEmailModal = () => {
        this.setState({
            isSendEmailModalOpen: !this.state.isSendEmailModalOpen,
        });
    };

    toggleCloseReportModal = () => {
        this.setState({
            isCloseReportModalOpen: !this.state.isCloseReportModalOpen,
        });
    };

    cleanAlertsMessages = (field) => {
        setTimeout(() => {
            this.setState({
                [field]: '',
            });
        }, 5000);
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <Alert
                    variant='success'
                    className='mb-4'
                    show={!!this.state.doneMessage}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.doneMessage}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Can run='ORDERS_REFUND'>
                        <Button
                            className='btn-secondary btn-bold mx-2 btn'
                            disabled={
                                this.state.orderReport.status === 'closed'
                            }
                            onClick={this.toggleReturnPaymentModal}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.RETURN_MONEY',
                            })}
                        </Button>
                    </Can>
                    <Can run='ORDERCASES_EDIT'>
                        <Button
                            className='btn-success btn-bold mx-2 btn'
                            disabled={
                                this.state.orderReport.status === 'closed'
                            }
                            onClick={this.toggleSendEmailModal}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.SEND_EMAIL',
                            })}
                        </Button>
                    </Can>
                    <Can run='ORDERCASES_EDIT'>
                        <Button
                            className='btn-danger btn-bold mx-2 btn'
                            disabled={
                                this.state.orderReport.status === 'closed'
                            }
                            onClick={this.toggleCloseReportModal}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.CLOSE_REPORT',
                            })}
                        </Button>
                    </Can>
                    <Button
                        as={NavLink}
                        to='/orders-reports'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card data-test='detailOrderReportComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'ORDERS_REPORTS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.orderReport.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.TOPIC_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.orderReport.topic
                                        ? this.props.intl.formatMessage({
                                              id: `ORDERS_REPORTS.TOPIC_${this.state.orderReport.topic.toUpperCase()}`,
                                          })
                                        : null}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.STATUS_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.orderReport.status
                                        ? this.props.intl.formatMessage({
                                              id: `ORDERS_REPORTS.STATUS_${this.state.orderReport.status.toUpperCase()}`,
                                          })
                                        : null}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.CREATED_AT_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.orderReport.createdAt &&
                                        new Date(
                                            this.state.orderReport.createdAt,
                                        ).toLocaleDateString()}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.BUYER_NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.orderReport.buyerFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.SELLER_NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.orderReport.sellerFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.TOTAL_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.orderReport.order.total &&
                                        `$ ${parseFloat(
                                            this.state.orderReport.order.total,
                                        ).toFixed(2)}`}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'ORDERS_REPORTS.ORDER_CREATED_AT_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.orderReport.order.createdAt &&
                                        new Date(
                                            this.state.orderReport.order.createdAt,
                                        ).toLocaleDateString()}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.PAYABLE_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.orderReport.order.payable &&
                                        `$ ${parseFloat(
                                            this.state.orderReport.order
                                                .payable,
                                        ).toFixed(2)}`}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.PAYED_AT_LABEL',
                                    })}
                                </label>
                                <p>
                                    {(this.state.orderReport.order.payedAt &&
                                        new Date(
                                            this.state.orderReport.order.payedAt,
                                        ).toLocaleDateString()) ||
                                        '-'}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS_REPORTS.ORDER_STATUS_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.orderReport.order.status
                                        ? this.props.intl.formatMessage({
                                              id: `ORDERS.STATUS_${this.state.orderReport.order.status.toUpperCase()}`,
                                          })
                                        : null}
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {this.state.orderReport.payments.length > 0 && (
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ORDERS_REPORTS.PAYMENTS_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            {this.state.orderReport.payments.map(
                                (payment, i) => (
                                    <>
                                        <Row key={i}>
                                            <Col sm={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.PAYMENT_ID_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>{payment.paymentId}</p>
                                            </Col>
                                            <Col sm={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.PAYMENT_STATUS_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: `ORDERS.PAYMENT_STATUS_${payment.status.toUpperCase()}`,
                                                        },
                                                    )}
                                                </p>
                                            </Col>
                                            <Col
                                                sm={6}
                                                className={
                                                    i + 1 !==
                                                    this.state.orderReport
                                                        .payments.length
                                                        ? 'mb-4'
                                                        : ''
                                                }
                                            >
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.PAYMENT_AMOUNT_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>
                                                    ${' '}
                                                    {payment.amount.toFixed(2)}
                                                </p>
                                            </Col>
                                        </Row>
                                        {i + 1 !==
                                            this.state.orderReport.payments
                                                .length && <hr />}
                                    </>
                                ),
                            )}
                        </Card.Body>
                    </Card>
                )}
                {this.state.orderReport.shipments.length > 0 && (
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ORDERS_REPORTS.SHIPMENTS_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            {this.state.orderReport.shipments.map(
                                (shipment, i) => (
                                    <>
                                        <Row key={i}>
                                            <Col sm={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.SHIPMENT_ID_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>{shipment.shipmentId}</p>
                                            </Col>
                                            <Col sm={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.SHIPMENT_CARRIER_NAME_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>{shipment.carrierName}</p>
                                            </Col>
                                            <Col sm={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.SHIPMENT_TRACKING_NUMBER_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>{shipment.trackingNumber}</p>
                                            </Col>
                                            <Col sm={6}>
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.SHIPMENT_ESTIMATED_DELIVERY_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>
                                                    {new Date(
                                                        shipment.estimatedDelivery,
                                                    ).toLocaleDateString()}
                                                </p>
                                            </Col>
                                            <Col
                                                sm={6}
                                                className={
                                                    i + 1 !==
                                                    this.state.orderReport
                                                        .shipments.length
                                                        ? 'mb-4'
                                                        : ''
                                                }
                                            >
                                                <label className='field-title'>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id:
                                                                'ORDERS_REPORTS.SHIPMENT_STATUS_LABEL',
                                                        },
                                                    )}
                                                </label>
                                                <p>
                                                    {this.props.intl.formatMessage(
                                                        {
                                                            id: `ORDERS.SHIPMENT_STATUS_${shipment.status.toUpperCase()}`,
                                                        },
                                                    )}
                                                </p>
                                            </Col>
                                        </Row>
                                        {i + 1 !==
                                            this.state.orderReport.shipments
                                                .length && <hr />}
                                    </>
                                ),
                            )}
                        </Card.Body>
                    </Card>
                )}
                {this.state.orderReport.reviews.length > 0 && (
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ORDERS_REPORTS.REVIEWS_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            {this.state.orderReport.reviews.map((review, i) => (
                                <>
                                    <Row key={i}>
                                        <Col sm={6}>
                                            <label className='field-title'>
                                                {this.props.intl.formatMessage({
                                                    id:
                                                        'ORDERS_REPORTS.REVIEWS_COMMENTS_LABEL',
                                                })}
                                            </label>
                                            <p>{review.comments}</p>
                                        </Col>
                                        <Col sm={6}>
                                            <label className='field-title'>
                                                {this.props.intl.formatMessage({
                                                    id:
                                                        'ORDERS_REPORTS.REVIEWS_CREATED_AT_LABEL',
                                                })}
                                            </label>
                                            <p>
                                                {new Date(
                                                    review.createdAt,
                                                ).toLocaleDateString()}
                                            </p>
                                        </Col>
                                        <Col
                                            sm={6}
                                            className={
                                                i + 1 !==
                                                this.state.orderReport.shipments
                                                    .length
                                                    ? 'mb-4'
                                                    : ''
                                            }
                                        >
                                            <label className='field-title'>
                                                {this.props.intl.formatMessage({
                                                    id:
                                                        'ORDERS_REPORTS.REVIEWS_STATUS_AT_LABEL',
                                                })}
                                            </label>
                                            <p>
                                                {this.props.intl.formatMessage({
                                                    id: `ORDERS_REPORTS.STATUS_${review.status.toUpperCase()}`,
                                                })}
                                            </p>
                                        </Col>
                                    </Row>
                                    {i + 1 !==
                                        this.state.orderReport.reviews
                                            .length && <hr />}
                                </>
                            ))}
                        </Card.Body>
                    </Card>
                )}
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'ORDERS_REPORTS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchOrder}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.isReturnPaymentModalOpen}
                    onHide={this.toggleReturnPaymentModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <Alert
                                show={this.state.orderReport.order?.payedAt}
                                variant='info'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'ORDERS_REPORTS.CHARGE_SELLER_DISABLED',
                                })}
                            </Alert>
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.RETURN_MONEY',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReturnMoneyForm
                            toggleReturnPaymentModal={
                                this.toggleReturnPaymentModal
                            }
                            disableChargeSeller={
                                !!this.state.orderReport.order?.payedAt
                            }
                            loading={this.state.loading}
                            handleSubmit={this.onSubmit}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.isSendEmailModalOpen}
                    onHide={this.toggleSendEmailModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.SEND_EMAIL',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SendEmailForm
                            toggleSendEmailModal={this.toggleSendEmailModal}
                            loading={this.state.loading}
                            handleSubmit={this.onSubmit}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.isCloseReportModalOpen}
                    onHide={this.toggleCloseReportModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.CLOSE_REPORT',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CloseReportForm
                            toggleCloseReportModal={this.toggleCloseReportModal}
                            loading={this.state.loading}
                            handleSubmit={this.onSubmit}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default injectIntl(OrderReportDetail);
