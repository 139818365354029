import React from 'react';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import StarterList from '../../components/starterList/StarterList';
import {SupportStatus} from '../../common/config/constants';

export class SupportCaseList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cases: [],
            page: 1,
            pageCount: 12,
            total: 0,
            searchTimeout: 0,
            userId: '',
            startsAt: '',
            endsAt: '',
            status: '',
        };
    }

    componentDidMount() {
        if (this.props.location?.state?.successMessage) {
            setTimeout(
                () => this.props.history.replace({successMessage: ''}),
                5000,
            );
        }

        return this.fetchSupportCases();
    }

    fetchSupportCases = async (page = 1) => {
        try {
            const result = await API.supportCases.get(
                page,
                this.state.status,
                this.state.userId,
                this.state.startsAt,
                this.state.endsAt,
            );

            this.setState({
                cases: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
            });
        } catch (e) {
            this.setState({
                cases: [],
            });
        }
    };

    onPageChange = (page) => {
        return this.fetchSupportCases(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchSupportCases();
            }, 300),
        });
    };

    onAutocompleteOrDateSearchChange = (value, name) => {
        this.setState({
            [name]: value?.id || value || '',
            searchTimeout: setTimeout(() => {
                return this.fetchSupportCases(1);
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                return this.fetchSupportCases();
            },
        );
    };

    handleAutocompleteSearch = async (query) => {
        const {items: users} = await API.users.get(1, query);
        return users.map((user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
        }));
    };

    render() {
        return (
            <>
                <StarterList
                    data-test='supportCaseListComponent'
                    hideSearchInput={true}
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    onAutocompleteOrDateSearchChange={
                        this.onAutocompleteOrDateSearchChange
                    }
                    handleAutocompleteSearch={this.handleAutocompleteSearch}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.USER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.COMMENTS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.STATUS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.CREATED_AT_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.cases.map((supportCase) => ({
                            content: [
                                supportCase.id,
                                supportCase.userFullName,
                                supportCase.comments,
                                this.props.intl.formatMessage({
                                    id: `SUPPORT_CASES.STATUS_${supportCase.status.toUpperCase()}`,
                                }),
                                new Date(
                                    supportCase.createdAt,
                                ).toLocaleDateString(),
                            ],
                            link: `/support-cases/${supportCase.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            name: 'userId',
                            value: this.state.userId,
                            autocomplete: true,
                            labelKey: 'name',
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.TYPE_HERE_TO_SEARCH_BY_USER',
                            }),
                        },
                        {
                            name: 'startsAt',
                            value: this.state.startsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_FROM',
                            }),
                        },
                        {
                            name: 'endsAt',
                            value: this.state.endsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_TO',
                            }),
                        },
                        {
                            name: 'status',
                            value: this.state.status,
                            placeholder: this.props.intl.formatMessage({
                                id: 'SUPPORT_CASES.STATUS_PLACEHOLDER',
                            }),
                            options: Object.values(SupportStatus).map(
                                (value) => ({
                                    value,
                                    label: this.props.intl.formatMessage({
                                        id: `SUPPORT_CASES.STATUS_${value.toUpperCase()}`,
                                    }),
                                }),
                            ),
                        },
                    ]}
                />
            </>
        );
    }
}

export default injectIntl(SupportCaseList);
