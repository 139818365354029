import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import SupportCaseList from './SupportCaseList';
import SupportCaseDetail from './SupportCaseDetail';

function SupportCases() {
    const intl = useIntl();

    return (
        <Switch data-test='supportCasesComponent'>
            <PrivateRoute
                permission='SUPPORTCASES_INDEX'
                exact
                path='/support-cases'
                component={withLayout(
                    SupportCaseList,
                    intl.formatMessage({id: 'ROUTES.SUPPORT_CASES.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='SUPPORTCASES_SHOW'
                exact
                path='/support-cases/:id'
                component={withLayout(
                    SupportCaseDetail,
                    intl.formatMessage({id: 'ROUTES.SUPPORT_CASES.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default SupportCases;
