import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';

import Form from '../../components/form/category/Form';
import API from '../../common/utils/API';

class CreatCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            shouldRedirectToIndex: false,
            loading: false,
        };
    }

    componentDidMount() {
        return this.fetchAttributes();
    }

    fetchAttributes = async () => {
        const {attributes} = await API.categories.attributes();

        this.setState({attributes});
    };

    onSubmit = async (input) => {
        this.setState({loading: true});
        try {
            const req = {...input};
            const {iconUrl = ''} = await this.handleSaveIcon(req.icon[0]);

            req.iconUrl = iconUrl;
            req.name = {es: req.nameEs, en: req.nameEn};
            req.bgColor = req.bgColor.replace('#', '');
            req.fgColor = req.fgColor.replace('#', '');

            delete req.icon;
            delete req.nameEs;
            delete req.nameEn;

            await API.categories.save(req);

            this.setState({
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            this.setState({loading: false});
        }
    };

    handleSaveIcon = async (icon) => {
        const formData = new FormData();

        formData.append('picture', icon);

        return await API.media.save(formData);
    };

    render() {
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/categories',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id:
                                'CATEGORIES.CATEGORY_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <Form
                loading={this.state.loading}
                handleSubmit={this.onSubmit}
                attributes={this.state.attributes}
            />
        );
    }
}

export default injectIntl(CreatCategory);
