export default class Notification {
    constructor({
        id = 0,
        title = '',
        body = '',
        data = {},
        readAt = null,
        createdAt = null,
    } = {}) {
        this.id = id;
        this.title = title;
        this.body = body;
        this.data = data || {};
        this.readAt = readAt && new Date(readAt);
        this.createdAt = createdAt && new Date(createdAt);
    }

    get formattedDate() {
        return this.createdAt?.toLocaleDateString();
    }

    get link() {
        let link = '';
        switch (this.data?.entityType) {
            case 'posts':
                link += `/posts`;
                break;
            case 'postReports':
                link += `/complains`;
                break;
            case 'supportCases':
                link += `/support-cases`;
                break;
            case 'orderCases':
                link += `/orders-reports`;
                break;
            case 'returns':
                link += `/returns`;
                break;
            default:
                return link;
        }
        if (this.data?.entityId) {
            link += `/${this.data?.entityId}`;
        }
        return link;
    }
}
