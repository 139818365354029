import React from 'react';
import {NavLink} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import * as FeatherIcons from 'react-icons/fi';

import {useIntl} from 'react-intl';

import './Menu.scss';

function Menu() {
    const intl = useIntl();

    return (
        <Nav className='menu'>
            <Nav.Link as={NavLink} to='/' exact={true}>
                <FeatherIcons.FiGrid />
                <span>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</span>
            </Nav.Link>
            <Nav.Item as='a' className='menu-separator'>
                <FeatherIcons.FiMoreHorizontal /> <span>Configuración</span>
            </Nav.Item>
            <Nav.Link as={NavLink} to='/users'>
                <FeatherIcons.FiUsers />
                <span>{intl.formatMessage({id: 'MENU.USERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/roles'>
                <FeatherIcons.FiUserCheck />
                <span>{intl.formatMessage({id: 'MENU.ROLES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/categories'>
                <FeatherIcons.FiBookmark />
                <span>{intl.formatMessage({id: 'MENU.CATEGORIES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/brands'>
                <FeatherIcons.FiTag />
                <span>{intl.formatMessage({id: 'MENU.BRANDS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/posts'>
                <FeatherIcons.FiClipboard />
                <span>{intl.formatMessage({id: 'MENU.POSTS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/complains'>
                <FeatherIcons.FiAlertOctagon />
                <span>{intl.formatMessage({id: 'MENU.COMPLAINS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/orders'>
                <FeatherIcons.FiDollarSign />
                <span>{intl.formatMessage({id: 'MENU.ORDERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/orders-reports'>
                <FeatherIcons.FiHelpCircle />
                <span>{intl.formatMessage({id: 'MENU.ORDERS_REPORTS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/returns'>
                <FeatherIcons.FiRotateCcw />
                <span>{intl.formatMessage({id: 'MENU.RETURNS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/faqs'>
                <FeatherIcons.FiHelpCircle />
                <span>{intl.formatMessage({id: 'MENU.FAQS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/support-cases'>
                <FeatherIcons.FiMail />
                <span>{intl.formatMessage({id: 'MENU.SUPPORT_CASES'})}</span>
            </Nav.Link>
        </Nav>
    );
}

export default Menu;
