import React from 'react';
import StarterList from '../../components/starterList/StarterList';
import API from '../../common/utils/API';
import {injectIntl} from 'react-intl';

export class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            // Meta for pagination
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            // Search parameters
            searchTimeout: 0,
            searchKeyword: '',
        };
    }

    componentDidMount() {
        API.users.get().then((result) => {
            this.setState({
                users: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
            });
        });
    }

    loadDataForPage = (pageNumber) => {
        API.users.get(pageNumber, this.state.searchKeyword).then((result) => {
            this.setState({
                users: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
            });
        });
    };

    handlePageChange = (pageNumber) => {
        this.loadDataForPage(pageNumber);
    };

    handleSearchKeywordChange = (event) => {
        const self = this;
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            searchKeyword: event.target.value,
            searchTimeout: setTimeout(function () {
                self.loadDataForPage(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = () => {
        this.setState(
            {
                pageSize: 1,
                searchKeyword: '',
            },
            function () {
                this.loadDataForPage(1);
            },
        );
    };

    render() {
        return (
            <>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    searchKeyword={this.state.searchKeyword}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_FIRST_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_LAST_NAME_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_ROLE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'USERS.USER_EMAIL_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.users.map((user) => ({
                            content: [
                                user.id,
                                user.firstName,
                                user.lastName,
                                user.roles || '-',
                                user.email,
                            ],
                            link: `/users/${user.id}`,
                        })),
                    }}
                    addNewPermission={'USERS_CREATE'}
                    addNewLink={`users/create`}
                />
            </>
        );
    }
}

export default injectIntl(UserList);
