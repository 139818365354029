export default class SupportCase {
    constructor({
        id = 0,
        user = {},
        comments = '',
        status = 'pending',
        createdAt = '',
        reviews = [],
    } = {}) {
        this.id = id;
        this.user = user;
        this.comments = comments;
        this.status = status;
        this.createdAt = createdAt;
        this.reviews = reviews;
    }

    get userFullName() {
        return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
    }
}
