import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import CategoryList from './CategoryList';
import CreateCategory from './CreateCategory';
import CategoryDetail from './CategoryDetail';
import EditCategory from './EditCategory';

import withLayout from '../../components/ui/Layout';

function Categories() {
    const intl = useIntl();

    return (
        <Switch>
            <PrivateRoute
                permission='CATEGORIES_SHOW'
                exact
                path='/categories'
                component={withLayout(
                    CategoryList,
                    intl.formatMessage({id: 'ROUTES.CATEGORIES.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='CATEGORIES_CREATE'
                path='/categories/create'
                component={withLayout(
                    CreateCategory,
                    intl.formatMessage({id: 'ROUTES.CATEGORIES.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='CATEGORIES_SHOW'
                exact
                path='/categories/:id'
                component={withLayout(
                    CategoryDetail,
                    intl.formatMessage({id: 'ROUTES.CATEGORIES.SHOW'}),
                )}
            />
            <PrivateRoute
                permission='CATEGORIES_EDIT'
                exact
                path='/categories/:id/edit'
                component={withLayout(
                    EditCategory,
                    intl.formatMessage({id: 'ROUTES.CATEGORIES.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default Categories;
