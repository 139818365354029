import Order from './Order';

export default class Return {
    constructor({
        id = 0,
        status = '',
        total = '0',
        createdAt = '',
        details = [],
        order = {},
        shipment = {},
        payment = {},
        seller = {},
        user = {},
        fees = [],
        refundable = false,
    } = {}) {
        this.id = id;
        this.status = status;
        this.total = parseFloat(total);
        this.createdAt = createdAt;
        this.details = details;
        this.order = new Order(order);
        this.shipment = shipment ?? {};
        this.payment = payment ?? {};
        this.seller = seller ?? {};
        this.user = user ?? {};
        this.fees = fees;
        this.refundable = refundable;
    }

    get buyerFullName() {
        return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
    }

    get sellerFullName() {
        return `${this.seller.firstName || ''} ${this.seller.lastName || ''}`;
    }

    get formatDate() {
        if (!this.createdAt) {
            return '';
        }
        return new Date(this.createdAt).toLocaleDateString();
    }

    get formatOrderDate() {
        if (!this.order.createdAt) {
            return '';
        }
        return new Date(this.order.createdAt).toLocaleDateString();
    }

    get formatDeliveryDate() {
        if (!this.shipment.deliveredAt) {
            return '';
        }
        return new Date(this.shipment.deliveredAt).toLocaleDateString();
    }

    get formatRefundDate() {
        if (!this.payment.createdAt) {
            return '';
        }
        return new Date(this.payment.createdAt).toLocaleDateString();
    }
}
