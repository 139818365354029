import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import * as Fi from 'react-icons/fi';
import {useIntl} from 'react-intl';

import StarterList from '../../components/starterList/StarterList';
import API from '../../common/utils/API';

function Dashboard() {
    const intl = useIntl();
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(0);
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = async (page = 1) => {
        const result = await API.notifications.get(page);
        setPage(page);
        setSize(result.pageSize);
        setTotal(result.total);
        setItems(result.items);
    };

    const onNotificationClick = async (i) => {
        const notification = items[i];
        if (!notification) {
            return;
        }
        await API.notifications.read(notification.id);
        history.push(notification.link);
    };

    return (
        <StarterList
            data-test='notificationsListComponent'
            hideSearchInput={true}
            onPageChange={(p) => fetchNotifications(p)}
            page={page}
            pageSize={size}
            total={total}
            onRowClick={onNotificationClick}
            data={{
                headerTitles: [
                    intl.formatMessage({id: 'NOTIFICATIONS.TITLE'}),
                    intl.formatMessage({id: 'NOTIFICATIONS.BODY'}),
                    intl.formatMessage({id: 'NOTIFICATIONS.DATE'}),
                    '',
                ],
                rows: items.map((notif) => ({
                    content: [
                        notif.title,
                        notif.body,
                        notif.formattedDate,
                        notif.readAt ? (
                            <Fi.FiCheck size='20' />
                        ) : (
                            <Fi.FiBell size='20' />
                        ),
                    ],
                })),
            }}
        />
    );
}

export default Dashboard;
