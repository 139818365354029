import {API} from '../API';
import PaginatedList from '../PaginatedList';
import SupportCase from '../../models/SupportCase';

const supportCases = {
    get: async (
        page = 1,
        status = '',
        userId = '',
        startsAt = '',
        endsAt = '',
    ) => {
        const {data: response} = await API.get(
            `/support-cases/?page=${page}&status=${status}&userId=${userId}&startsAt=${startsAt}&endsAt=${endsAt}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((i) => new SupportCase(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/support-cases/${id}`);

        return {
            supportCase: new SupportCase(response.data),
        };
    },
    review: async (input, id) => {
        const {data: response} = await API.post(
            `support-cases/${id}/review`,
            input,
        );

        return {
            supportCase: new SupportCase(response.data),
        };
    },
};

export default supportCases;
