import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import {Row, Col} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import SwiperCore, {Navigation, Pagination} from 'swiper';

import API from '../../common/utils/API';
import Category from '../../common/models/Category';
import Can from '../../common/security/Can';
import Modal from 'react-bootstrap/Modal';
import LoadingButton from '../../components/form/LoadingButton';

SwiperCore.use([Navigation, Pagination]);

class CategoryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: new Category(),
            error: '',
            isLoadErrorModalOpen: false,
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchCategory();
    }

    fetchCategory = async () => {
        this.setState({loading: true});
        try {
            const {category} = await API.categories.detail(
                this.props.match.params.id,
            );

            this.setState({
                category: {...category},
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
                isLoadErrorModalOpen: true,
            });
        }
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Can run='CATEGORIES_EDIT'>
                        <Button
                            as={NavLink}
                            to={`/categories/${this.props.match.params.id}/edit`}
                            className='btn-info btn-bold mx-2'
                        >
                            {this.props.intl.formatMessage({
                                id: 'CATEGORIES.EDIT_BUTTON_TEXT',
                            })}
                        </Button>
                    </Can>
                    <Button
                        as={NavLink}
                        to='/categories'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'CATEGORIES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.category.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.ICON_LABEL',
                                    })}
                                </label>
                                <div
                                    style={{
                                        backgroundColor: `#${this.state.category.bgColor}`,
                                        width: '50px',
                                        borderRadius: '5%',
                                    }}
                                >
                                    <img
                                        hidden={!this.state.category.iconUrl}
                                        className='img-fluid'
                                        src={this.state.category.iconUrl}
                                        alt={`text`}
                                        width='48px'
                                    />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.NAME_ES_LABEL',
                                    })}
                                </label>
                                <p>{this.state.category.names?.['es']}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.NAME_ES_LABEL',
                                    })}
                                </label>
                                <p>{this.state.category.names?.['en']}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.BGCOLOR_LABEL',
                                    })}
                                </label>
                                <p>#{this.state.category.bgColor}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.FGCOLOR_LABEL',
                                    })}
                                </label>
                                <p>#{this.state.category.fgColor}</p>
                            </Col>
                            {this.state.category.attributes.map(
                                (attribute, i) => (
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {attribute.name}
                                        </label>
                                        {Object.entries(
                                            attribute.options.reduce(
                                                (groups, option) => {
                                                    option.group =
                                                        option.group || '';
                                                    if (!groups[option.group]) {
                                                        groups[
                                                            option.group
                                                        ] = [];
                                                    }
                                                    groups[option.group].push(
                                                        option.label,
                                                    );
                                                    return groups;
                                                },
                                                {},
                                            ),
                                        ).map(([group, options]) => (
                                            <p>
                                                {group ? group + ': ' : ''}
                                                {options.join(', ')}
                                            </p>
                                        ))}
                                    </Col>
                                ),
                            )}
                        </Row>
                    </Card.Body>
                </Card>

                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'CATEGORIES.PIERCING_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.WIDTH_LABEL',
                                    })}
                                </label>
                                <p>{this.state.category.width} cm</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.HEIGHT_LABEL',
                                    })}
                                </label>
                                <p>{this.state.category.height} cm</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.LENGTH_LABEL',
                                    })}
                                </label>
                                <p>{this.state.category.length} cm</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'CATEGORIES.WEIGHT_LABEL',
                                    })}
                                </label>
                                <p>{this.state.category.weight} kg</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'CATEGORIES.CATEGORY_ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'CATEGORIES.CATEGORY_ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id:
                                    'CATEGORIES.CATEGORY_ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchCategory}
                        >
                            {this.props.intl.formatMessage({
                                id:
                                    'CATEGORIES.CATEGORY_ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(CategoryDetail);
