import {PostStatus} from '../../common/config/constants';

export default class Post {
    constructor({
        id = 0,
        user = {},
        brand = {},
        category = {},
        pictures = {},
        name = '',
        description = '',
        location = '',
        address = {},
        attributes = [],
        price = 0,
        originalPrice = 0,
        quantityAvailable = 0,
        status = PostStatus.PENDING,
        isActive = false,
        isCensored = false,
        openShop = false,
        isSalable = false,
        isBargainable = false,
        createdAt = '',
    } = {}) {
        this.id = id;
        this.user = user;
        this.brand = brand;
        this.brandId = brand.id || -1;
        this.brandName = !brand.id ? brand.name : undefined;
        this.category = category;
        this.categoryId = category.id;
        this.pictures = pictures;
        this.name = name;
        this.description = description;
        this.location = location;
        this.attributes = attributes;
        this.address = address;
        this.addressId = address.id;
        this.price = price;
        this.originalPrice = originalPrice;
        this.quantityAvailable = quantityAvailable;
        this.status = status;
        this.isActive = isActive;
        this.isCensored = isCensored;
        this.openShop = openShop;
        this.isSalable = isSalable;
        this.isBargainable = isBargainable;
        this.attributes = attributes;
        this.createdAt = createdAt;
        attributes.forEach((attr) => {
            this[`attribute${attr.id}`] = attr.optionId;
        });
    }

    get userName() {
        return `${this.user.firstName} ${this.user.lastName}`.trim();
    }

    get formatDate() {
        if (!this.createdAt) {
            return '';
        }
        return new Date(this.createdAt).toLocaleDateString();
    }
}
